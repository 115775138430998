.has-danger > input,
.has-danger > .v-select > .dropdown-toggle {
  border-color: red !important;
}

.selected-tag {
  margin-left: 17px !important;
  color: #575962 !important;
}

.blockUI {
  z-index: 10000 !important;
  left: 0 !important;
}

.blockUI > .m-blockui {
  margin-left: 0px !important;
  display: inline-block !important;
}

.dropzone {
  border: 1px solid #ebedf2;
}

.dropzone-custom-title {
  color: #45bbeb;
}

.subtitle {
  color: #314b5f;
}

.vue-dropzone {
  min-height: 60vh !important;
}

@media (max-height: 900px) {
  .modal-dialog {
    margin: 5px auto;
  }
}

.vue-popover {
  font-size: 12px;
  width: auto !important;
  z-index: 9999 !important;
}

.text-red {
  color: red;
}

.text-gold {
  color: gold;
}

.text-green {
  color: lawngreen;
}
